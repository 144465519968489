:root {
  --background-color: #000;
  --color: #fff;
  --font-family: monospace;
  --overlay-text-color: #e33;
  font-size: 14px;
}

::selection {
  color: var(--background-color);
  background: var(--overlay-text-color);
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--overlay-text-color);
  height: 100%;
  align-items: center;
  margin: 0;
  font-size: 250%;
  display: flex;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin: auto;
  position: relative;
}

.container .scene {
  place-content: center;
  display: grid;
}

a {
  color: inherit;
}

.text {
  text-transform: uppercase;
  z-index: 1;
  text-align: center;
  color: var(--overlay-text-color);
  align-self: center;
  margin-top: 0;
  font-size: 200%;
  font-weight: 800;
  display: none;
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
}

.text p {
  margin: 0;
}

.text .controls {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

.text .controls--down {
  transform: rotate(180deg);
}

.text .controls--left {
  transform: rotate(-90deg);
}

.text .controls--right {
  transform: rotate(90deg);
}

.text .controls path {
  fill: var(--overlay-text-color);
}

.text .controls rect {
  stroke: var(--overlay-text-color);
}

.is-loaded .text {
  display: block;
}

.song {
  z-index: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  display: none;
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
}

.song figcaption {
  background-color: var(--overlay-text-color);
  text-transform: lowercase;
  color: var(--color);
  padding: .6rem 1.2rem;
  font-style: italic;
  font-weight: bold;
  display: inline-block;
}

.song audio {
  width: 100%;
  border-radius: 4px;
  display: none;
}

.song .song-player {
  vertical-align: sub;
  display: inline-block;
}

.song .song-player .play {
  display: block;
}

.song .song-player .pause {
  display: none;
}

.song .song-player svg {
  width: 1em;
  height: 1em;
}

.song .song-player svg path {
  fill: var(--color);
}

.is-loaded .song {
  display: flex;
}

.is-playing .song-player .play {
  display: none;
}

.is-playing .song-player .pause {
  display: block;
}

.scene {
  margin: 0 auto;
  position: absolute;
  inset: 0;
}

.scene canvas {
  display: none;
}

.scene .loader {
  text-align: center;
  display: none;
}

.scene .loader .loader-text {
  width: 60ch;
  font-family: monospace;
}

.scene .loadScene {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scene.is-loading .loader {
  display: block;
}

.scene.is-loading .loadScene, .scene.is-loaded .loadScene {
  display: none;
}

.scene.is-loaded canvas {
  display: block;
}

.about-us {
  z-index: 2;
  display: none;
  position: absolute;
  bottom: 16rem;
  left: 50%;
  transform: translateX(-50%);
}

.is-loaded .about-us {
  display: block;
}

.button {
  pointer-events: auto;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  background: #e7e7e7;
  border: none;
  margin: 0;
  padding: 1.5rem 3rem;
  display: inline-block;
  position: relative;
}

.button-fx {
  border: 2px solid var(--overlay-text-color);
  color: var(--overlay-text-color);
  background-color: #0000;
  border-radius: 50%;
  font-weight: 900;
}

.button-fx > span {
  white-space: nowrap;
  transition: opacity .1s;
  display: inline-block;
}

.button-fx:not(:disabled):hover > span {
  opacity: 0;
}

.button-fx .marquee {
  transform: rotate(-2.75deg);
}

.marquee {
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.marquee__inner {
  width: fit-content;
  --offset: 1rem;
  --move-initial: calc(-25% + var(--offset) );
  --move-final: calc(-50% + var(--offset) );
  transform: translate3d(var(--move-initial), 0, 0);
  opacity: 0;
  animation: marquee 1s linear infinite paused;
  display: flex;
  position: relative;
}

.marquee span {
  text-align: center;
  white-space: nowrap;
  padding: 1.5rem .5rem;
  font-style: italic;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.button-fx:not(:disabled):hover .marquee__inner {
  opacity: 1;
  transition-duration: .6s;
  animation-play-state: running;
}

dialog {
  max-width: 55rem;
  background-color: var(--background-color);
  color: var(--overlay-text-color);
  border: none;
  border-radius: .4rem;
  padding: 1.6rem;
  font-size: 1.2em;
}

dialog h2 {
  margin-top: 0;
}

dialog::backdrop {
  background-color: #0006;
}

.closeModal {
  cursor: pointer;
  color: var(--overlay-text-color);
  background: none;
  border: none;
  padding: .4rem;
  font-size: 1.6em;
  line-height: 1;
  position: absolute;
  top: .8rem;
  right: 1.4rem;
  transform: rotate(45deg);
}

.closeModal:hover, .closeModal:active {
  outline: 0;
}

@media (min-width: 65rem) {
  :root {
    font-size: 12px;
  }

  .text {
    font-size: 120%;
    bottom: 2rem;
  }

  .about-us {
    bottom: 2rem;
    left: 2rem;
    transform: none;
  }

  .song {
    text-align: right;
  }
}

.loading {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.loading div {
  width: 13px;
  height: 13px;
  background: var(--overlay-text-color);
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 33px;
}

.loading div:nth-child(1) {
  animation: loading1 .6s infinite;
  left: 8px;
}

.loading div:nth-child(2) {
  animation: loading2 .6s infinite;
  left: 8px;
}

.loading div:nth-child(3) {
  animation: loading2 .6s infinite;
  left: 32px;
}

.loading div:nth-child(4) {
  animation: loading3 .6s infinite;
  left: 56px;
}

@keyframes loading1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loading3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loading2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

/*# sourceMappingURL=index.c2671556.css.map */
