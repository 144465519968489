:root {
  --background-color: #000;
  --color: #fff;
  --font-family: monospace;
  --overlay-text-color: rgb(238, 51, 51);
  font-size: 14px;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--background-color);
  background: var(--overlay-text-color);
}

::selection {
  color: var(--background-color);
  background: var(--overlay-text-color);
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--overlay-text-color);
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 250%;
}

.container {
  // display: inline-block;
  margin: auto;
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 1920px;

  .scene {
    display: grid;
    place-content: center;
  }
}

a {
  color: inherit;
}

.text {
  font-size: 200%;
  align-self: center;
  // font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 800;
  position: absolute;
  z-index: 1;
  bottom: 18px;
  left: 0;
  text-align: center;
  right: 0;
  margin-top: 0;
  color: var(--overlay-text-color);
  display: none;

  p {
    margin: 0;
  }

  .controls {
    width: 1em;
    height: 1em;
    vertical-align: middle;

    &--down {
      transform: rotate(180deg);
    }

    &--left {
      transform: rotate(-90deg);
    }

    &--right {
      transform: rotate(90deg);
    }

    path {
      fill: var(--overlay-text-color);
    }
    rect {
      stroke: var(--overlay-text-color);
    }
  }
}

.is-loaded {
  .text {
    display: block;
  }
}

.song {
  position: absolute;
  z-index: 1;
  top: 18px;
  left: 0px;
  right: 0px;
  text-align: center;
  font-size: 1.2em;
  display: none;
  align-items: center;
  justify-content: center;

  figcaption {
    background-color: var(--overlay-text-color);
    text-transform: lowercase;
    font-style: italic;
    font-weight: bold;
    display: inline-block;
    padding: 0.6rem 1.2rem;
    color: var(--color);

    // margin-right: 32px;
    // margin-top: 8px;
  }

  audio {
    width: 100%;
    border-radius: 4px;
    display: none;
    // background-color: var(--overlay-text-color);
  }

  .song-player {
    display: inline-block;
    vertical-align: sub;

    .play {
      display: block;
    }

    .pause {
      display: none;
    }

    svg {
      width: 1em;
      height: 1em;
      path {
        fill: var(--color);
      }
    }
  }
}

.is-loaded {
  .song {
    display: flex;
  }
}

.is-playing .song-player {
  .play {
    display: none;
  }
  .pause {
    display: block;
  }
}

.scene {
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  canvas {
    display: none;
  }

  .loader {
    display: none;
    text-align: center;

    .loader-text {
      font-family: monospace;
      width: 60ch;
    }
  }

  .loadScene {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.is-loading {
    .loader {
      display: block;
    }
  }

  &.is-loading,
  &.is-loaded {
    .loadScene {
      display: none;
    }
  }

  &.is-loaded {
    canvas {
      display: block;
    }
  }
}

// about

.about-us {
  position: absolute;
  bottom: 16rem;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  z-index: 2;
}

.is-loaded .about-us {
  display: block;
}

// buttons
.button {
  pointer-events: auto;
  cursor: pointer;
  background: #e7e7e7;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
}

// FX button

.button-fx {
  font-weight: 900;
  // font-size: 1.25rem;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--overlay-text-color);
  color: var(--overlay-text-color);
}

.button-fx > span {
  display: inline-block;
  transition: opacity 0.1s;
  white-space: nowrap;
}

.button-fx:not(:disabled):hover > span {
  opacity: 0;
}

.button-fx .marquee {
  transform: rotate(-2.75deg);
}

.marquee {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  --offset: 1rem;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 1s linear infinite;
  animation-play-state: paused;
  opacity: 0;
}

.marquee span {
  text-align: center;
  white-space: nowrap;
  font-style: italic;
  padding: 1.5rem 0.5rem;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.button-fx:not(:disabled):hover .marquee__inner {
  animation-play-state: running;
  opacity: 1;
  transition-duration: 0.6s;
}

// Modal

dialog {
  border-radius: 0.4rem;
  padding: 1.6rem;
  border: none;
  // margin: 1rem;
  max-width: 55rem;
  background-color: var(--background-color);
  color: var(--overlay-text-color);
  font-size: 1.2em;

  h2 {
    margin-top: 0;
  }
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

.closeModal {
  border: none;
  background: transparent;
  transform: rotate(45deg);
  padding: 0.4rem;
  font-size: 1.6em;
  position: absolute;
  top: 0.8rem;
  line-height: 1;
  right: 1.4rem;
  cursor: pointer;
  color: var(--overlay-text-color);
}

.closeModal:hover,
.closeModal:active {
  outline: 0px;
}

@media (min-width: 65rem) {
  :root {
    font-size: 12px;
  }

  .text {
    font-size: 120%;
    bottom: 2rem;
  }
  .about-us {
    left: 2rem;
    bottom: 2rem;
    transform: none;
  }

  .container {
    // width: 80%;
    // height: 80%;
  }

  .song {
    text-align: right;
  }
}

// animation

.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--overlay-text-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading div:nth-child(1) {
  left: 8px;
  animation: loading1 0.6s infinite;
}
.loading div:nth-child(2) {
  left: 8px;
  animation: loading2 0.6s infinite;
}
.loading div:nth-child(3) {
  left: 32px;
  animation: loading2 0.6s infinite;
}
.loading div:nth-child(4) {
  left: 56px;
  animation: loading3 0.6s infinite;
}
@keyframes loading1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
